import React from "react"
import styled from "styled-components"

const CheckboxContainer = styled.div`
  margin-bottom: 20px;

  input[type="checkbox"] {
    opacity: 0;
  }

  label {
    position: relative;
    display: inline-block;

    padding-left: 16px;
    cursor: pointer;
  }

  input:disabled + label {
    cursor: progress;
    color: #a9a9a9;
  }

  label::before,
  label::after {
    transition: all 0.25s ease-in;
    position: absolute;
    content: "";

    /*Needed for the line-height to take effect*/
    display: inline-block;
  }

  /*Outer box of the fake checkbox*/
  label::before {
    height: 20px;
    width: 20px;

    border: 1px solid #c9c9ca;
    border-radius: 4px;

    left: -17px;
    top: -3px;
  }

  /*Checkmark of the fake checkbox*/
  label::after {
    height: 6px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-color: white;

    transform: rotate(-45deg);

    left: -12px;
    top: 2px;
  }

  /*Hide the checkmark by default*/
  input[type="checkbox"] + label::after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label::after {
    content: "";
  }

  input[type="checkbox"]:checked + label::before {
    content: "";
    background: #8a59ef;
    border-color: #8a59ef;
  }
`
const randId = () => `checkbox_${Math.random()}`

export default function Checkbox({ labelText, id = randId(), ...rest }) {
  return (
    <CheckboxContainer>
      <input type="checkbox" id={id} {...rest} />
      <label htmlFor={id}>{labelText}</label>
    </CheckboxContainer>
  )
}
