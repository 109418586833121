import React, { useState, useEffect } from "react"

import Layout from "./components/_Layout"
import SectionHeader from "./components/_SectionHeader"
import Checkbox from "./components/_Checkbox"

import { get } from "lodash"
import debounce from "../../utils/debounce"

import authStore from "../../stores/auth"

function EmailPreference({ prefKey, labelText }) {
  const subscribed = get(authStore.state.user, prefKey, false)
  const [disabled, setDisabled] = useState(false)
  const handleChange = debounce(async () => {
    setDisabled(true)
    await authStore.updateProfile(prefKey, !subscribed)
    setDisabled(false)
  })

  return (
    <Checkbox
      disabled={disabled}
      checked={subscribed}
      onChange={handleChange}
      labelText={labelText}
    />
  )
}

function Notifications() {
  useEffect(() => {
    authStore.getProfile()
  }, [])

  return (
    <Layout
      name="Notifications"
      title="Change your profile and account settings"
    >
      <SectionHeader title="Alerts & Notifications" />
      <p>Send me:</p>
      <EmailPreference
        prefKey="ip_rotation_email_notification"
        labelText="Emails for IP Rotation"
      />
      <EmailPreference
        prefKey="rockpoints_earned_email_notification"
        labelText="Emails when you earn RockPoints"
      />
      <EmailPreference
        prefKey="rockpoints_spent_email_notification"
        labelText="Emails when you spend RockPoints"
      />
      <br />
      <br />
      <SectionHeader title="Get MountProxies news, announcements, and product updates" />
      <EmailPreference
        prefKey="rockpoints_news_email_notification"
        labelText="MountProxies News and Information"
      />
    </Layout>
  )
}

export default Notifications
